<template>
  <div class="content">
    <div class="row">
      <div class="col-4">
        <h6 v-if="strategy">Order Products on "{{ strategy.name }}" page</h6>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-4">
        <div class="mb-4">Choose some products</div>
        <products-input v-if="productsReady" v-model="selectedProductsArray" type="name" key="products" @ready="enableSync()" />
      </div>

      <div class="col-8 ps-5">
        <div class="products">
          <div class="row">
            <div class="col p-0">
              <div>Rearrange the products by dragging them.</div>
            </div>
            <div class="col-auto">
              <button class="vave-btn btn-green" :disabled="savingOrder" @click="saveOrder()">Save</button>
            </div>
          </div>
          <draggable v-model="orderedProductsArray" group="products" @start="drag=true" @end="drag=false" class="row">
            <div v-for="product in orderedProductsArray" :key="'dragg-prod-'+product.id" class="product d-flex flex-column">
              <div>{{product.name}}</div>
              <img :src="image(getProductImage(product), 200, 200)" style="pointer-events:none;" />
            </div>
            <div v-for="product in 12-orderedProductsArray.length" :key="'dragg-placeholder-product'+product" class="product product-placeholder d-flex flex-column">
              <div class="name">Product</div>
              <img :src="placeholder" class="image" style="pointer-events:none;" />
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../http.js";
import draggable from "vuedraggable";
import ProductsInput from "./widgets/helpers/Products";
import imageProxy from "./../imageProxy";

export default {
  components: { draggable, ProductsInput },
  data: function () {
    return {
      placeholder: require("./../assets/product-image-placeholder.png"),
      selectedProductsArray: [],
      orderedProductsArray: [],
      productsReady: false,
      productsInputReady: false,
      strategyId: this.$route.params.id,
      strategy: null,
      savingOrder: false,
    };
  },
  mounted() {
    this.setup();
  },
  watch: {
    selectedProductsArray: {
      deep: true,
      handler: function (val, oldVal) {
        // We need this complex logic to avoid the order being reset when we add a new product
        if (val.length > oldVal.length) {
          // We added a new product
          let newProduct = val.filter((item) => !oldVal.includes(item));
          let exists = this.orderedProductsArray.filter((item) => {
            return item.id == newProduct[0].id;
          });
          if (exists.length == 0) {
            // Add it to the orderedProductsArray if it doesn't exist
            this.orderedProductsArray.push(newProduct[0]);
          }
        } else if (val.length < oldVal.length) {
          // We removed a product, let's find the ID and remove it from the orderedProductsArray
          let newArray = val.map((item) => item.id);
          let oldArray = oldVal.map((item) => item.id);
          let removedProductId = oldArray.filter((x) => !newArray.includes(x));
          this.orderedProductsArray = this.orderedProductsArray.filter(
            (item) => item.id != removedProductId
          );
        }
      },
    },
  },
  methods: {
    image: imageProxy.image,
    saveOrder() {
      this.savingOrder = true;
      axios
        .put("api/admin/ordering-strategies/" + this.strategyId, {
          products_order: this.orderedProductsArray.map((item) => item.id),
        })
        .then(
          (response) => {
            this.$toast.success(response.data.message);
          },
          (error) => {
            console.log("error", error.response);
            this.$toast.error(
              "There was a problem saving the products order: " +
                error.response.data.message +
                ". Check the console for more details."
            );
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.savingOrder = false;
          }, 3000);
        });
    },
    getProductImage(item) {
      let p = this.$store.state.products.filter((product) => {
        return product.name == item.name;
      });
      if (p[0] && p[0].images) {
        return p[0].images[0].origin_src;
      }
      return "";
    },
    enableSync() {
      this.productsInputReady = true;
    },
    setup() {
      // Get the Products order for that category
      axios
        .get("/api/admin/ordering-strategies/" + this.strategyId)
        .then((response) => {
          // loop for each product id and get the product data from the $store.state.products
          var productsData = [];
          this.strategy = response.data.data;
          response.data.data.products_order.forEach((p) => {
            let product = this.$store.state.products.filter((product) => {
              return product.id == p;
            });
            if (product[0]) {
              productsData.push(product[0]);
            }
          });

          this.selectedProductsArray = productsData;
          this.orderedProductsArray = productsData;
          this.productsReady = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  max-width: 800px;
  .product {
    overflow: hidden;
    // don't break space
    background: white;
    white-space: nowrap;
    cursor: move;
    padding: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    .name {
      font-weight: bold;
    }
    max-width: calc(25% - 10px);
    &.product-placeholder {
      pointer-events: none;
      .name {
        color: #ccc;
      }
      .image {
        opacity: 0.1;
      }
    }
  }
}
</style>